<template>
  <div class="">
    <v-container class="my-0 my-md-16 py-0  px-0 px-md-3 pb-md-16">
      <v-row class="no-gutters">
        <v-col xl="8" offset-xl="2" md="10" offset-md="1">
          <v-data-table
            :headers="headers"
            :items="musteriler"
            :search="search"
            class="elevation-24"
            style="border-radius:6px"
          >
            <template v-slot:top>
              <v-toolbar
                flat
                color="primary"
                height="120px"
                dark
                :class="
                  $vuetify.breakpoint.smAndDown
                    ? 'rounded-t-0'
                    : 'rounded-t-lg pl-3'
                "
              >
                <v-toolbar-title class="d-flex align-center">
                  <v-icon large>
                    mdil-bank
                  </v-icon>
                  <span class="pl-6 text-h4">{{ $t("musteriler") }}</span>
                </v-toolbar-title>
                <v-spacer />
                <div
                  v-if="$vuetify.breakpoint.mdAndDown ? false : true"
                  class="d-flex py-6 align-center"
                >
                  <v-text-field
                    v-model="search"
                    class="pr-6"
                    append-icon="mdi-magnify"
                    :label="$t('ara')"
                    hide-details
                  />
                </div>
                <v-btn
                  color="accent"
                  dark
                  depressed
                  class="mb-2"
                  @click="dialog = true"
                >
                  {{ $t("yeniEkle") }}
                </v-btn>
              </v-toolbar>
            </template>

            <template v-slot:header.ad="{ item }">
              {{ $t("ad") }}
            </template>
            <template v-slot:header.soyad="{ item }">
              {{ $t("soyad") }}
            </template>
            <template v-slot:header.tel="{ item }">
              {{ $t("telefon") }}
            </template>
            <template v-slot:header.bolge="{ item }">
              {{ $t("bolge") }}
            </template>
            <template v-slot:header.durum="{ item }">
              {{ $t("durum") }}
            </template>
            <template v-slot:header.kod="{ item }">
              {{ $t("kodu") }}
            </template>

            <template v-slot:item.bolge="{ item }">
              {{ musteriBolge(item.bolge) }}
            </template>
            <template v-slot:item.durum="{ item }">
              <v-icon v-if="item.durum" color="accent" medium>
                mdi-check-bold
              </v-icon>
              <v-icon v-else color="primary" medium>
                mdil-cancel
              </v-icon>
            </template>
            <template v-slot:item.kod="{ item }">
              <v-icon v-if="item.kod" color="accent" medium>
                mdi-check-bold
              </v-icon>
              <v-icon v-else color="primary" medium>
                mdil-cancel
              </v-icon>
            </template>
            <template v-slot:item.reklam="{ item }">
              <v-icon v-if="item.reklam" color="accent" medium>
                mdi-check-bold
              </v-icon>
              <v-icon v-else color="primary" medium>
                mdil-cancel
              </v-icon>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.mdAndDown ? true : false"
      scrollable
      max-width="500px"
      transition="dialog-bottom-transition"
    >
      <v-form ref="form" v-model="valid" :disabled="disabled" lazy-validation>
        <v-card>
          <v-card-title class="py-3 py-md-6">
            <div class="text-center text-h4  text-md-h3 font-weight-medium">
              {{ formTitle }}
            </div>
            <v-spacer />
            <v-btn
              x-small
              fab
              elevation="0"
              color="grey lighten-2"
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <qrcode-stream
            v-if="editedIndex === -1 && !musteriDevam && dialog"
            @decode="onDecode"
          />
          <v-card-text v-else class="px-2 py-0" style="max-height: 520px;">
            <div class="scroll pa-3">
              <v-row no-gutters align="center" class="mb-6 mb-md-9">
                <v-col :cols="editedItem.kod ? 12 : 8">
                  <v-text-field
                    v-model="editedItem.kod"
                    outlined
                    rounded
                    hide-details
                    :label="$t('kodu')"
                    disabled
                    required
                  />
                </v-col>
                <v-col v-if="!editedItem.kod" cols="4" class="pl-3">
                  <v-btn
                    depressed=""
                    rounded=""
                    class="text-capitalize"
                    block
                    @click="dialogKod = true"
                  >
                    {{ $t("kodTanimla") }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-text-field
                v-model="editedItem.ad"
                outlined
                rounded
                hide-details
                :label="$t('ad')"
                class="mb-6 mb-md-9"
                required
                :rules="gerekliRules"
              />
              <v-text-field
                v-model="editedItem.soyad"
                outlined
                rounded
                hide-details
                :label="$t('soyad')"
                class="mb-6 mb-md-9"
                required
                :rules="gerekliRules"
              />
              <v-text-field
                v-model="editedItem.tel"
                outlined
                rounded
                hide-details
                :label="$t('telefon')"
                class="mb-6 mb-md-9"
                type="tel"
              />
              <v-text-field
                v-model="editedItem.mail"
                outlined
                rounded
                hide-details
                class="mb-6 mb-md-9"
                :label="$t('eposta')"
                required
                :rules="emailRules"
                type="email"
              />
              <v-select
                v-model="editedItem.bolge"
                outlined
                rounded
                hide-details
                class="mb-6 mb-md-9"
                :label="$t('bolge')"
                :rules="gerekliRules"
                :items="bolgeler"
                item-text="ad"
                item-value=".key"
                required
              />
              <v-textarea
                v-model="editedItem.adres"
                outlined
                rounded
                hide-details
                class="mb-6 mb-md-9"
                :label="$t('adres')"
              />
              <v-switch
                v-model="editedItem.durum"
                outlined
                rounded
                hide-details
                class="mb-6 mb-md-9"
                color="primary"
                inset
                :label="`${editedItem.durum ? $t('onayli') : $t('onaysiz')}`"
              />
            </div>
          </v-card-text>
          <v-divider v-if="!(editedIndex === -1 && !musteriDevam)" />
          <v-card-actions
            v-if="!(editedIndex === -1 && !musteriDevam)"
            class=" px-3 primary rounded-0"
          >
            <div class="px-3" style="width:100%">
              <v-btn
                :disabled="!valid || disabled"
                block
                :large="$vuetify.breakpoint.mdAndDown ? false : true"
                depressed=""
                class="rounded-pill text-capitalize text-body-1"
                color="primary"
                @click="save()"
              >
                {{ formAction }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dialogKod" width="500">
      <div class="">
        <qrcode-stream v-if="dialogKod" @decode="setDecode" />
      </div>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      rounded="pill"
      color="red"
      :timeout="-1"
      centered
      elevation="24"
      style="opacity:0.9"
    >
      <div class="text-h3 font-weight-light text-center pa-3 pa-md-6">
        {{ $t("tanimliKod") }}
      </div>
    </v-snackbar>
  </div>
</template>
<script>
import { veri, depo } from "./../db";
export default {
  name: "MusterilerView",
  data: () => ({
    snackbar: false,
    dialog: false,
    dialogDelete: false,
    dialogKod: false,
    musteriDevam: false,
    search: "",
    focus: false,
    musteriler: [],
    bolgeler: [],
    editedIndex: -1,
    editedItem: {
      kod: "",
      ad: "",
      soyad: "",
      tel: "",
      mail: "",
      bolge: null,
      adres: "",
      durum: true,
      reklam: true,
      kayitTarih: ""
    },
    defaultItem: {
      kod: "",
      ad: "",
      soyad: "",
      tel: "",
      mail: "",
      bolge: null,
      adres: "",
      durum: true,
      reklam: true,
      kayitTarih: ""
    },
    disabled: false,
    valid: true,
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],
    gerekliRules: [v => !!v || "Aciklama is required"]
  }),
  firebase: {
    musteriler: veri.ref("musteriler"),
    bolgeler: veri.ref("bolgeler")
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t("yeniEkle") : this.$t("duzenle");
    },
    formAction() {
      return this.editedIndex === -1 ? this.$t("olustur") : this.$t("guncelle");
    },
    headers() {
      return [
        {
          text: this.$t("ad"),
          value: "ad"
        },
        {
          text: this.$t("soyad"),
          value: "soyad"
        },
        {
          text: this.$t("telefon"),
          sortable: false,
          value: "tel"
        },
        {
          text: this.$t("bolge"),
          value: "bolge"
        },
        {
          text: this.$t("durum"),
          align: "right",
          sortable: false,
          value: "durum"
        },
        {
          text: this.$t("kodu"),
          align: "right",
          sortable: false,
          value: "kod"
        },
        {
          text: this.$t("reklam"),
          align: "right",
          sortable: false,
          value: "reklam"
        },
        {
          text: "",
          value: "actions",
          align: "right",
          filterable: false
        }
      ];
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogKod(val) {
      if (!val) {
        this.snackbar = false;
      }
    }
  },
  methods: {
    onDecode(decodedString) {
      const gelen = decodedString.search("uID");
      if (gelen > -1) {
        const deger = decodedString.split("uID=");
        const kontrol = _.find(this.musteriler, {
          kod: deger[1]
        });
        this.musteriDevam = true;
        if (_.isObject(kontrol)) {
          this.editItem(kontrol);
        } else {
          this.editedItem.kod = deger[1];
        }
      }
    },
    setDecode(decodedString) {
      const gelen = decodedString.search("uID");
      if (gelen > -1) {
        const deger = decodedString.split("uID=");

        const kontrol = _.find(this.musteriler, {
          kod: deger[1]
        });
        if (_.isObject(kontrol)) {
          this.snackbar = true;
        } else {
          this.editedItem.kod = deger[1];
          this.dialogKod = false;
        }
      }
    },
    musteriBolge(val) {
      let sonuc = "";
      const deger = _.find(this.bolgeler, {
        ".key": val
      });
      if (_.isObject(deger)) {
        sonuc = deger.ad;
      }
      return sonuc;
    },
    editItem(item) {
      this.editedIndex = this.musteriler.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      confirm(this.$t("silOnay")) &&
        veri
          .ref("musteriler/" + item[".key"])
          .remove()
          .then(() => {
            veri
              .ref("siparisler/")
              .orderByChild("musteriID")
              .equalTo(item[".key"])
              .once("value")
              .then(function(snapshot) {
                snapshot.forEach(childSnapshot => {
                  veri.ref("siparisler/" + childSnapshot.key).remove();
                });
              });
          });
    },
    close() {
      this.reset();
      this.musteriDevam = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    save() {
      if (this.$refs.form.validate()) {
        this.editedItem.kayitTarih = Date.now();
        if (this.editedIndex > -1) {
          veri
            .ref("musteriler/" + this.musteriler[this.editedIndex][".key"])
            .set(this.editedItem)
            .then(this.close());
        } else {
          veri
            .ref("musteriler")
            .push(this.editedItem)
            .then(this.close());
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.v-card__text .scroll {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden !important;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 20px;
  }
}

.v-card__text .scroll:hover {
  &::-webkit-scrollbar-thumb {
    background: #bcc0c4;
  }
}
</style>
